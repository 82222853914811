import api from "services/api";
import { trackFacebookWithRedundancy } from "services/facebookTracker";
import { trackGoogleAnalytics } from "services/googleAnalyticsTracker";

import {
  auctions_show_path,
  search_ad_path,
  customers_whatsapp_contact_path,
  pre_bids_create_path,
} from "utils/routes";

import useWindowSizes from "hooks/useWindowSizes";

import { cpfValidate } from "utils/documentValidate";
import { reactive, ref, toRaw } from "vue";

const initialState = reactive({
  currentFlow: "",
  auctionType: "",
  activeTab: "",
  isSubmitting: false,
  isDisabled: false,
  isRegisteringBid: false,
  previuosTab: "",
  bidValue: "",
  proposalValue: "",
  proposalConditions: "",
  message: "",
  redirectButtonLink: "",
  isModalOpen: false,
  cvData: {},
  trackData: {},
});

const state = reactive({
  currentTrackedUser: {},
  auction: null,
  batch: null,
  currentConversion: {},
  currentFlow: "",
  auctionType: "",
  activeTab: "",
  isSubmitting: false,
  isDisabled: false,
  isRegisteringBid: false,
  previuosTab: "",
  bidValue: "",
  bidState: "",
  bidTermsAccepted: false,
  preBid: {},
  proposalValue: "",
  proposalConditions: "",
  message: "",
  redirectButtonLink: "",
  isModalOpen: false,
  cvData: {},
  trackData: {},
});

const closeModal = () => {
  let restrictions = [
    "currentTrackedUser",
    "auction",
    "batch",
    "cvData",
    "trackData",
    "currentConversion",
  ];

  for (const filter in state) {
    if (!restrictions.includes(filter)) {
      state[filter] = initialState[filter];
    }
  }
};

const createConversion = () => {
  state.isSubmitting = true;

  const phone = state.currentTrackedUser.phone.replace(/\(|\)|\-|\s/g, "");

  state.currentConversion.lp_conversion.phone =
    phone.slice(0, 2) + " " + phone.slice(2);

  state.currentConversion.lp_conversion.name = state.currentTrackedUser.name;
  state.currentConversion.lp_conversion.email = state.currentTrackedUser.email;

  state.redirectButtonLink =
    state.currentFlow == "register"
      ? searchUrl()
      : auctions_show_path(state.auction.url_friendly);
  state.currentConversion.lp_conversion.document_type = "CPF";
  state.currentConversion.lp_conversion.document_number =
    state.currentTrackedUser.cpf;

  state.currentConversion.additional_infos.push({
    question: "CPF",
    answer: state.currentTrackedUser.cpf,
  });

  switch (state.currentFlow) {
    case "proposal":
      buildProposalData();
      break;
    case "bid":
      buildBidData();
      break;
    case "register":
      buildRegisterData();
      break;
    case "message":
      buildMessageData();
      break;
  }

  api
    .post(state.currentConversion.action, state.currentConversion)
    .then((res) => {
      state.currentTrackedUser.customerId = res.data.customer_id;
      state.cvData["Conversion LP Title"] =
        state.currentConversion.lp_conversion.landing_page_title;
      mixpanel.track("Product - User Converted", state.cvData);

      trackFacebookWithRedundancy("Criou Pré-Cadastro", res.data.customer_id);

      trackFacebookWithRedundancy("Conversão Lote", res.data.customer_id);
      trackGoogleAnalytics("conversao_lote");

      if (res.data.new_pipe) {
        trackFacebookWithRedundancy("Novo Pipe", res.data.customer_id);
        trackGoogleAnalytics("novo_pipe");
      }

      if (res.data.new_repurchase_pipe) {
        trackFacebookWithRedundancy("Novo Pipe Revenda", res.data.customer_id);
        trackGoogleAnalytics("novo_pipe_revenda");
      }

      switch (state.currentFlow) {
        case "proposal":
          if (
            state.currentConversion.lp_conversion.landing_page_title ===
            "Proposta registrada"
          ) {
            state.activeTab = "confirm";
          }
          break;
        case "bid":
          state.currentConversion.lp_conversion.landing_page_title =
            state.auction.title;
          state.currentConversion.lp_conversion.current_flow =
            state.currentFlow;
          api.post(state.currentConversion.action, state.currentConversion);
          registerBid();
          break;
        case "message":
          startWhatsappChat();
          break;
      }

      if (
        state.currentConversion.lp_conversion.landing_page_title !==
        "Identificação de proposta"
      ) {
        state.currentConversion.additional_infos = [];
      }
    })
    .catch(() => {
      alert(
        "Um erro inesperado aconteceu. Por favor, tente novamente ou contate o suporte.",
      );
    })
    .finally(() => {
      state.isSubmitting = false;
    });
};

const setActiveTab = (tab) => {
  state.activeTab = tab;
};

const completedIdentification = () => {
  const { name, email, phone, cpf } = state.currentTrackedUser;
  if (!name || !email || !phone || !cpf) return false;

  return true;
};

const searchUrlParams = {
  query: "",
  classifications: ["Genética", "Reposição"],
  genetic: ["P.O.", "P.A.", "P.C.", "CEIP"],
  page: 1,
};

const searchUrl = () => {
  const searchParams = new URLSearchParams(
    toRaw({ ...searchUrlParams, q: searchUrlParams.query }),
  );

  let deleteKeys = [];

  for (let pair of searchParams.entries()) {
    if (!pair[0] || pair[1].length == 0) {
      deleteKeys.push(pair[0]);
    }
  }

  for (let key in deleteKeys) {
    searchParams.delete(deleteKeys[key]);
  }

  return `${search_ad_path()}?${searchParams.toString()}`;
};

const { isMobile } = useWindowSizes();

const startWhatsappChat = () => {
  api
    .get(customers_whatsapp_contact_path(state.currentTrackedUser.customerId))
    .then((res) => {
      if (isMobile.value) {
        window.open(
          `whatsapp://send?phone=${res.data.phone_whatsapp}&text=Oi, me chamo ${state.currentTrackedUser.name}. ${state.message}`,
          "_blank",
        );
      } else {
        window.open(
          `https://api.whatsapp.com/send?phone=${res.data.phone_whatsapp}&text=Oi, me chamo ${state.currentTrackedUser.name}. ${state.message}`,
          "_blank",
        );
      }

      closeModal();
    });
};

const buildProposalData = () => {
  state.cvData["Section"] = "Proposal";

  if (state.activeTab == "proposalIdentification") {
    state.currentConversion.lp_conversion.landing_page_title =
      "Identificação de Proposta";

    return;
  }

  state.currentConversion.additional_infos.push(
    {
      question: "Valor da proposta",
      answer: state.proposalValue.replace(/[R$. ]/g, "").replace(",", "."),
    },
    {
      question: "Condições de negociação",
      answer: state.proposalConditions,
    },
    {
      question: "Fez proposta?",
      answer: "Sim",
    },
    {
      question: "[Evento] Proposta",
      answer: state.auction.title,
    },
  );

  state.currentConversion.lp_conversion.landing_page_title =
    "Proposta registrada";

  state.cvData["Proposal Value"] = state.proposalValue
    .replace(/[R$. ]/g, "")
    .replace(",", ".");
};

const buildBidData = () => {
  state.currentConversion.additional_infos.push(
    {
      question: "Valor do lance",
      answer: state.bidValue,
    },
    {
      question: "Fez um lance?",
      answer: "Sim",
    },
    {
      question: "[Evento] Lance",
      answer: state.auction.title,
    },
  );

  state.currentConversion.lp_conversion.landing_page_title = `Lance Gerado - ${state.auction.title}`;

  state.cvData["Section"] = "Bid";
  state.cvData["Schedule Bid"] = state.bidValue;
};

const buildRegisterData = () => {
  state.currentConversion.additional_infos.push({
    question: "[Evento] Cadastro",
    answer: state.auction.title,
  });

  state.cvData["Section"] = "Event Register";
};

const buildMessageData = () => {
  state.currentConversion.lp_conversion.landing_page_title =
    "Contato solicitado (Whatsapp)";

  state.currentConversion.additional_infos.push(
    {
      question: "Mensagem contato realizado (Whatsapp)",
      answer: state.message,
    },
    {
      question: "[Evento] Contato",
      answer: state.auction.title,
    },
  );

  state.cvData["Section"] = "Contact";
};

const registerBid = () => {
  api
    .post(pre_bids_create_path(), {
      customer_id: state.currentTrackedUser?.customerId,
      url_friendly_auction: state.auction.url_friendly,
      url_friendly_batch: state.batch.url_friendly,
      price:
        state.bidValue * state.batch.installments * state.batch.animals_count,
      cpf: state.currentTrackedUser.cpf?.replaceAll(".", "").replace("-", ""),
      accepted_terms: state.bidTermsAccepted,
    })
    .then((res) => {
      state.bidState = res.data.pre_bid_status == 1 ? "approved" : "awaiting";
      state.preBid.batch_price = res.data.batch_price;
      state.preBid.bid_price = res.data.bid_price;
      state.activeTab = "confirm";
    })
    .catch((error) => {
      if (error.response.status === 409) {
        state.bidState = "conflict";
        return (state.activeTab = "confirm");
      }

      if (error.response.status === 422) {
        state.bidState = "closed";
        return (state.activeTab = "confirm");
      }

      alert(
        "Ocorreu um erro ao dar o lance, tente novamente. Se o problema persistir entre em contato conosco.",
      );
    })
    .finally(() => (state.isRegisteringBid = false));
};

const errorCPF = ref(false);
const isValidCPF = () => {
  const { cpf } = state.currentTrackedUser;
  if (!cpf) return false;

  const isValid = cpfValidate(cpf.replace(/[\.\-\/]/g, ""));
  errorCPF.value = !isValid && cpf.length === 14;

  return isValid;
};

export {
  state,
  createConversion,
  setActiveTab,
  closeModal,
  searchUrl,
  completedIdentification,
  isValidCPF,
  errorCPF,
};
